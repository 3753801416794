// Import minified CSS to keep the SCSS clean
@import url('./gb-styles.min.css');

h1,
h2,
h3,
h4,
h5,
h6 {
  font-variant-numeric: lining-nums;
  font-feature-settings: "lnum";
}

html {
  font-size: 16px !important;
}

img {
  max-width: 100%;
  height: auto;
}

ul.dropdown-content.select-dropdown {
  max-height: 300px;
}

ol {
  padding: 0 0 0 2rem;
}

ul,
ol {
  display: inline-block;
  margin-top: 0;
}

ul li,
ol li {
  margin-bottom: .75rem;
}

ul:not(.browser-default) {
  padding: 0 0 0 2rem;

  >li {
    list-style-type: initial;
  }
}

// Font styles
:root .has-text-primary-color {
  color: #3f4553;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: Raleway, sans-serif !important;
  text-transform: uppercase;
  color: #3f4553;
  line-height: 1;
  margin: 3rem 0 .5rem;
  font-weight: 700 !important;

  @media only screen and (max-width: 768px) {
    margin: 1.5rem 0 1rem;
  }

  strong {
    font-weight: 900 !important;
  }

  a {
    color: #78ccc5;
  }
}

h1,
h1+h2 {
  margin: 0;
}

h1 {
  font-size: 3.125rem;
  color: #3f4553;
}

h2 {
  font-size: 2rem;

  @media only screen and (max-width: 768px) {
    font-size: 2rem;
  }
}

h3 {
  font-size: 1.5rem;
}

h4 {
  font-size: 1.25rem;
}

h5 {
  font-size: 1.125rem;
}

h6 {
  font-size: 1rem;
}


h2.new-style {
  font-size: 2.25rem;
}

h3.new-style {
  font-size: 1.5rem;
}

// Utility classes

.m-0 {
  margin: 0;
}

.my-0 {
  margin-top: 0;
  margin-bottom: 0;
}

.mx-0 {
  margin-left: 0;
  margin-right: 0;
}



.p-0 {
  padding: 0;
}

.py-0 {
  padding-top: 0;
  padding-bottom: 0;
}

.px-0 {
  padding-left: 0;
  padding-right: 0;
}


.form-error {
  color: red;
}

.description {
  color: grey;
  font-size: 14px;
  padding: 5px 0;
  display: block;
}

p {
  font-size: 1.125rem;
  font-family: 'Open Sans', sans-serif;
  font-weight: 400;
  line-height: 1.5;
  margin-bottom: 1.5rem;
}

.media-oembed-content {
  display: block;
  max-width: 100%;
}

.media.align-center,
.media-library-item__preview-wrapper.align-center,
.align-center .field--type-image {
  text-align: center;
  max-width: 50%;
  margin-right: auto;
  margin-left: auto;

  &.media--type-remote-video {
    max-width: 80%;
  }

  @media only screen and (max-width: 767px) {
    &.media--type-remote-video {
      max-width: 100%;
      margin: 0;
    }
  }
}

.media.align-left,
.media-library-item__preview-wrapper.align-left,
.align-left .field--type-image {
  float: left;
  margin: 10px 15px 10px 0;
}

.media.align-right,
.media-library-item__preview-wrapper.align-right,
.align-right .field--type-image {
  float: right;
  margin: 10px 0px 10px 15px;

  &.media--type-remote-video {
    max-width: 80%;
  }

  @media only screen and (max-width: 767px) {
    &.media--type-remote-video {
      max-width: 100%;
      margin: 0;
    }
  }
}

.media.align-left,
.media.align-right,
.media-library-item__preview-wrapper.align-left,
.media-library-item__preview-wrapper.align-right,
.align-left .field--type-image,
.align-right .field--type-image {
  clear: both;
  max-width: 50%;

  &.media--type-remote-video {
    max-width: 80%;
  }

  @media only screen and (max-width: 767px) {
    &.media--type-remote-video {
      max-width: 100%;
      margin: 0;
    }
  }
}

main a {

  //color: #0071B3;
  &:hover {
    text-decoration: underline;
  }
}

.has-medium-font-size {
  font-size: 1.25rem !important;
}

.lato,
.open-sans {
  font-family: 'Open Sans', sans-serif !important;
}

.main-body {

  ol,
  p,
  ul {
    font-size: 1.125rem;

    li {

      ul {
        display: block;
      }

    }

  }

  a {
    color: #0071B3;

    &:hover {
      color: #018fe2;
      text-decoration: underline;
    }
  }
}

:target:before {
  content: "";
  display: block;
  height: 120px;
  /* fixed header height*/
  margin: -120px 0 0;
  /* negative fixed header height */
}

.alignfull {
  margin-left: calc(-50vw + 50%) !important;
  margin-right: calc(-50vw + 50%) !important;
  max-width: 100vw !important;
  width: auto !important;
  padding-left: 0 !important;
  padding-right: 0 !important;
}

.alignwide {
  margin-left: calc(-42.5vw + 50%) !important;
  margin-right: calc(-42.5vw + 50%) !important;
  width: auto !important;
  max-width: 100vw !important;
  padding-left: 0 !important;
  padding-right: 0 !important;
}

.alignleft:not(.wp-block-embed) {
  display: inline !important;
  float: left;
  margin-right: 1.5em;
}

.alignright:not(.wp-block-embed) {
  display: inline !important;
  float: right;
  margin-left: 1.5em;
}

.aligncenter {
  clear: both;
  margin-left: auto !important;
  margin-right: auto !important;
}

.hidden,
.hide {
  display: none !important;
}

.visually-hidden {
  position: absolute !important;
  overflow: hidden;
  clip: rect(1px, 1px, 1px, 1px);
  width: 1px;
  height: 1px;
  word-wrap: normal;
}

.visually-hidden.focusable:active,
.visually-hidden.focusable:focus {
  position: static !important;
  overflow: visible;
  clip: auto;
  width: auto;
  height: auto;
}

.invisible {
  visibility: hidden;
}

a {
  color: inherit;
  text-decoration: none;
}

* {
  box-sizing: border-box;
}

img {
  max-width: 100%;
  height: auto;
}

form button.is-active {
  border: solid 4px #FED36B;
  background-color: #FED36B;
}

table {
  border: none;

  td {
    border: none;
    box-shadow: none;
    outline: none;
  }
}

table.table-bg-blue,
table.table-bg-charcoal {
  background: #b7e4e0;
  padding: 2rem;
  display: block;
  width: auto !important;
  position: relative;
  padding-bottom: 12px;
  font-size: 1.25rem;

  h5 {
    font-size: 1rem;
    margin: 3.75rem 0;
    line-height: 1.8;
    text-transform: none;
  }
}

.table-style-list,
.table-style-list-charcoal {
  padding-left: 48px;
  border: 0;
  display: block;

  tr {
    background-color: transparent;
  }

  ul li {
    list-style: none;
    position: relative;
    font-weight: 300;

    strong {
      font-weight: 300;
    }

    &:before {
      background: url("../public/assets/list-icon.png");
      content: '';
      width: 30px;
      height: 26px;
      display: inline-block;
      position: absolute;
      left: -35px;
      top: 5px;
    }
  }
}

.table-style-list-charcoal ul li:before {
  background: url("../public/assets/list-icon-charcoal.png");

}

.table-style-quote-bg,
.table-style-quote-bg-charcoal {
  background: url("../public/assets/bg0002.png");
  background-repeat: no-repeat !important;
  background-position: left top;
  padding-left: 48px;
  padding-top: 35px;
  padding-bottom: 30px;
  background-size: auto 100% !important;
  display: block;

  tr {
    margin: 0;
    padding: 0;
    position: relative;
    display: block;
    background: no-repeat;
    border: 0;

    td {
      margin: 0;
      padding: 0;
      border: 0;
      font-size: 20px;
      font-family: 'Open Sans';
      font-weight: 300;
      line-height: 29px;
    }
  }
}

.table-style-quote-bg-charcoal {
  background: url("../public/assets/bg0003.png");

}

table {
  border: 0;
  border-spacing: 0;
  font-size: 0.857em;
  margin: 10px 0;

  tbody {
    border-top: 1px solid #ccc;

    tr,
    tr.event {
      background: rgba(0, 0, 0, 0.063);
    }

    tr td,
    tr th {
      padding: 4px 9px;
      border: 1px solid #fff;
      text-align: left;
    }
  }
}

.map-wrapper {
  position: relative;
  padding-bottom: 0;
  min-height: calc(100vh - 270px) !important;

  @media only screen and (max-width: 767px) {
    padding-bottom: 6.5rem;
    min-height: 360px;
  }

  .eat-map-lbl {
    background-color: #f9f5f2;
    padding: 2px 4px;
    border-radius: 5px;
    box-shadow: 0 1px 1px rgb(0 0 0 / 50%);
    font-weight: 600;
  }

  .info-msg {
    position: absolute;
    text-align: center;
    top: 0px;
    z-index: 10;
    max-width: 500px;
    left: 30%;
    background-color: rgba(68, 68, 68, .85);
    padding: 2px 12px;
    border-radius: 3px;
    margin: 0;
    color: white;

    @media only screen and (max-width: 768px) {
      display: none;
    }
  }

  .legend-color {
    position: absolute;
    background: #fff;
    bottom: 0;
    left: 0;
    right: 0;
    margin: 0;
    width: 486px;
    border: solid 1px #d3d3d3;

    .item {
      padding-left: 45px;
      font-size: 12px;
      padding-top: 10px;
      padding-bottom: 10px;
      margin-left: 6px;
      background-position: 8px center !important;

      @media only screen and (min-width: 768px) {
        display: table-cell;
      }
    }

    .item.multiple {
      background: url("../public/images/maps/all.png");
      background-repeat: no-repeat;
      background-size: 32px;
    }

    .item.p-normal {
      background: url("../public/images/maps/home-for-sale.png");
      background-repeat: no-repeat;
      background-size: 32px;
    }

    .item.p-featured {
      background: url("../public/images/maps/featured-home-for-sale.png");
      background-repeat: no-repeat;
      background-size: 32px;
    }
  }
}

body:not(.not-search-body) {
  &::-webkit-scrollbar {
    display: none;
  }

  *::-webkit-scrollbar {
    display: none;
  }

  * {
    -ms-overflow-style: none;
    /* IE and Edge */
    scrollbar-width: none;
    /* Firefox */
  }

  @media only screen and (min-width: 769px) {
    height: 100vh;
    overflow: hidden;
  }
}

.listingWrapper {
  @media only screen and (min-width: 769px) {
    overflow: hidden !important;
    height: calc(100vh - 90px) !important;
  }
}

.infinite-scroll-component {
  @media only screen and (min-width: 769px) {
    min-width: calc(100% + 18px) !important;
    min-height: calc(100vh - 270px) !important;
    overflow-y: auto !important;
    width: 100%;
    padding-right: 20px;
    /* Increase/decrease this value for cross-browser compatibility */
    box-sizing: content-box;
    /* So the width will be 100% + 17px */
  }
}

.mapListingWrapper {
  overflow: hidden auto !important;
  height: auto !important;

  @media only screen and (min-width: 769px) {
    height: calc(100vh - 270px) !important;
  }

  @media only screen and (max-width: 767px) {
    height: auto !important;

    small {
      padding: 0 1rem;
      display: inline-block;
      margin-bottom: 1rem;
    }
  }
}

.map-container {
  height: calc(100vh - 270px) !important;

  @media only screen and (max-width: 767px) {
    height: calc(100vh - 179px) !important;
  }
}

.header-folded-corner {
  position: relative;
  background-color: #fed36b;
  padding: 1rem 1rem 1rem 3.5rem;
  display: inline-block;

  &:before {
    content: '';
    width: 0;
    height: 0;
    top: 50%;
    left: 0;
    transform: translateY(-50%);
    border-top: 30px solid transparent;
    border-bottom: 30px solid transparent;
    border-left: 40px solid #6fc9c2;
    position: absolute;
  }
}

// Box shadow

.box-shadow {
  box-shadow: 0 0 1px 1px white inset, 0 23px 21px -33px #000;
  background-color: white;
  padding: 1rem 1rem 0;
  margin: 2.1rem 0;

  .wp-block-columns {
    margin: 1rem 0;
  }
}

// Custom headers with background yellow colors

.column-pull-left {
  position: relative;
  z-index: 1;
  margin-right: 2rem;
  padding: 2.5rem 0;
  width: 50%;
  margin: 0;

  @media only screen and (min-width: 768px) {
    display: inline-block;
  }

  &:before {
    content: '' !important;
    position: absolute;
    top: 0;
    right: -2rem;
    width: 100vw;
    background-color: #fed36b;
    height: 100%;
    z-index: -1;
  }
}

// Arrow call to action

.arrow-call-to-action {
  background: #3d4552;
  color: #fff;
  padding: 20px;
  margin-bottom: 10px;
  list-style: none;
  font-size: 1.125rem;
  padding-left: 71px;
  position: relative;
  max-width: 850px;
  line-height: 31px;
  background: url("../public/assets/dot-arrow-right.png") top 1.75rem left 0.5rem no-repeat #3d4552;
  background-size: 47px 34px;
  position: relative;
  overflow: hidden;

  @media only screen and (min-width: 768px) {
    background-position: center left 0.5rem;
    padding-right: 105px;
    font-size: 1.8125rem;
  }

  &:before {
    @media only screen and (min-width: 768px) {
      width: 0;
      height: 0;
      border-right: 100px solid #6fc9c2;
      border-top: 102px solid transparent;
      content: '';
      z-index: 2;
      position: absolute;
      top: 0;
      right: 0;
    }
  }
}

// Lists with arrows

.list-with-arrows {
  padding-left: 2.5rem !important;

  li {
    position: relative;
    padding: 2rem 0;
    list-style: none !important;

    &:before {
      content: '';
      background: url("../public/assets/dot-arrow-right-green.png") top 0.1rem left no-repeat;
      width: 2rem;
      height: 2rem;
      left: -2.5rem;
      position: absolute;
    }
  }
}

// Green call to action

.green-call-to-action {
  background: #b7e4e0;
  padding: 20px;
  display: block;
  padding-left: 145px;
  width: auto !important;
  position: relative;
  padding-bottom: 12px;

  &:before {
    width: 0;
    height: 0;
    border-left: 150px solid #6fc9c2;
    border-bottom: 150px solid transparent;
    content: '';
    z-index: 1;
    position: absolute;
    left: 0;
    top: 0;
  }
}

// Set default styles for container

.material-container.row {
  max-width: 1280px;
  padding: 0 24px;
}

.custom-arrow-list .wp-block-image .alignright {
  float: right;
  margin: 1.4rem 0 0;
}

.wp-block-image {
  margin: 0;
  display: flex;

  img {
    height: auto;
  }
}

// Custom 3-Up CTA

.wp-block-columns.no-gutters {
  .wp-block-column:not(:first-child) {
    margin-left: 0;
  }

  * {
    margin: 0;
  }
}

// Blockquote

figure.wp-block-pullquote:not(.small) {
  border-left: 0;
  position: relative;
  margin: 5rem 0;
  min-height: 320px;

  @media only screen and (min-width: 768px) {
    min-height: 460px;
  }

  border: 0;

  blockquote {
    position: absolute;
    top: 50%;
    width: 80%;
    left: 50%;
    border: 0;
    margin: 0;
    padding: 0;
    transform: translate(-50%, -50%);

    p {
      font-size: 1.2rem !important;
    }
  }

  &:after,
  &:before {
    content: '';
    position: absolute;
    height: 460px;
    width: 400px;
    top: 0;
    background-size: 100%;
  }

  &:before {
    left: 0;
    top: -2.6rem;
    background-image: url("data:image/svg+xml,%3C%3Fxml version='1.0' encoding='utf-8'%3F%3E%3C!-- Generator: Adobe Illustrator 24.2.1, SVG Export Plug-In . SVG Version: 6.00 Build 0) --%3E%3Csvg version='1.1' id='Layer_1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' x='0px' y='0px' viewBox='0 0 142.8 330.6' style='enable-background:new 0 0 142.8 330.6;' xml:space='preserve'%3E%3Cstyle type='text/css'%3E .st0%7Bfill:%23FED36B;%7D .st1%7Bfill:%2342B5B5;%7D%0A%3C/style%3E%3Cg%3E%3Cg%3E%3Cpolygon class='st0' points='81,330.6 0,330.6 0,29.6 81,29.6 81,49.6 20,49.6 20,310.6 81,310.6 '/%3E%3C/g%3E%3Cpath class='st1' d='M122.8,49.8V25.2c0-6.7,1.8-12.2,5.4-16.5c3.6-4.4,8.4-7.3,14.6-8.7v10.9c-1.9,0.6-3.4,1.3-4.6,2.1 c-1.2,0.8-2.1,1.9-2.7,3s-1.1,2.5-1.3,4.1c-0.2,1.5-0.3,3.2-0.3,5v2.6h8.9v22H122.8z M93.1,49.8V25.2c0-6.7,1.8-12.2,5.4-16.5 c3.6-4.4,8.4-7.3,14.6-8.7v10.9c-1.9,0.6-3.4,1.3-4.6,2.1c-1.2,0.8-2.1,1.9-2.7,3c-0.6,1.2-1,2.5-1.3,4.1c-0.2,1.5-0.3,3.2-0.3,5 v2.6h8.9v22H93.1z'/%3E%3C/g%3E%3C/svg%3E%0A");
    background-position: top left;
    background-size: contain;
    background-repeat: no-repeat;
  }

  &:after {
    background-image: url("data:image/svg+xml,%3C%3Fxml version='1.0' encoding='utf-8'%3F%3E%3C!-- Generator: Adobe Illustrator 24.2.1, SVG Export Plug-In . SVG Version: 6.00 Build 0) --%3E%3Csvg version='1.1' id='Layer_1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' x='0px' y='0px' viewBox='0 0 142.8 333.6' style='enable-background:new 0 0 142.8 333.6;' xml:space='preserve'%3E%3Cstyle type='text/css'%3E .st0%7Bfill:%23FED36B;%7D .st1%7Bfill:%2342B5B5;%7D%0A%3C/style%3E%3Cg%3E%3Cg%3E%3Cpolygon class='st0' points='142.8,301 61.8,301 61.8,281 122.8,281 122.8,20 61.8,20 61.8,0 142.8,0 '/%3E%3C/g%3E%3Cpath class='st1' d='M19.9,283.7v24.6c0,6.7-1.8,12.2-5.4,16.5c-3.6,4.4-8.4,7.3-14.6,8.7v-10.9c1.9-0.6,3.4-1.3,4.6-2.1 s2.1-1.9,2.7-3c0.7-1.2,1.1-2.5,1.3-4.1c0.2-1.5,0.3-3.2,0.3-5v-2.6H0v-22H19.9z M49.6,283.7v24.6c0,6.7-1.8,12.2-5.4,16.5 c-3.6,4.4-8.4,7.3-14.6,8.7v-10.9c1.9-0.6,3.4-1.3,4.6-2.1c1.2-0.8,2.1-1.9,2.7-3c0.6-1.2,1-2.5,1.3-4.1c0.2-1.5,0.3-3.2,0.3-5 v-2.6h-8.9v-22H49.6z'/%3E%3C/g%3E%3C/svg%3E%0A");
    right: 0;
    background-position: top right;
    background-size: contain;
    background-repeat: no-repeat;
  }
}

figure.wp-block-pullquote.small {
  margin-top: 2rem;
  background-image: url("data:image/svg+xml,%3C%3Fxml version='1.0' encoding='utf-8'%3F%3E%3C!-- Generator: Adobe Illustrator 24.2.1  SVG Export Plug-In . SVG Version: 6.00 Build 0) --%3E%3Csvg version='1.1' id='Layer_1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' x='0px' y='0px' viewBox='0 0 275.6 290' style='enable-background:new 0 0 275.6 290%3B' xml:space='preserve'%3E%3Cstyle type='text/css'%3E.st0%7Bfill:%23FED36B%3B%7D.st1%7Bfill:%2342B5B5%3B%7D%3C/style%3E%3Cg%3E%3Cpolygon class='st0' points='19.7 290 0 290 0 0 19.7 0 19.7 7.8 7.8 7.8 7.8 282.2 19.7 282.2 '/%3E%3C/g%3E%3Cpath class='st1' d='M45.4 17.8V9c0-2.4 0.6-4.4 1.9-5.9c1.3-1.6 3-2.6 5.2-3.1v3.9c-0.7 0.2-1.2 0.5-1.6 0.7c-0.4 0.3-0.7 0.7-1 1.1s-0.4 0.9-0.5 1.5c-0.1 0.5-0.1 1.1-0.1 1.8v0.9h3.2v7.9L45.4 17.8L45.4 17.8z M34.8 17.8V9c0-2.4 0.6-4.4 1.9-5.9c1.3-1.6 3-2.6 5.2-3.1v3.9c-0.7 0.2-1.2 0.5-1.6 0.7c-0.4 0.3-0.7 0.7-1 1.1c-0.2 0.4-0.4 0.9-0.5 1.5c-0.1 0.5-0.1 1.1-0.1 1.8v0.9h3.2v7.9L34.8 17.8L34.8 17.8z'/%3E%3Cpath class='st1' d='M265 272.2v8.8c0 2.4-0.6 4.4-1.9 5.9c-1.3 1.6-3 2.6-5.2 3.1v-3.9c0.7-0.2 1.2-0.5 1.6-0.7s0.7-0.7 1-1.1c0.2-0.4 0.4-0.9 0.5-1.5c0.1-0.5 0.1-1.1 0.1-1.8v-0.9h-3.2v-7.9L265 272.2L265 272.2z M275.6 272.2v8.8c0 2.4-0.6 4.4-1.9 5.9c-1.3 1.6-3 2.6-5.2 3.1v-3.9c0.7-0.2 1.2-0.5 1.6-0.7s0.7-0.7 1-1.1c0.2-0.4 0.4-0.9 0.5-1.5c0.1-0.5 0.1-1.1 0.1-1.8v-0.9h-3.2v-7.9L275.6 272.2L275.6 272.2z'/%3E%3C/svg%3E");
  background-position: top left;
  background-size: contain;
  background-repeat: no-repeat;
}

.has-common-white-color {
  color: white;
}

.has-warning-main-color {
  color: #fed36b;
}

.has-small-font-size {
  font-size: 0.8125rem !important;
}

.has-normal-font-size {
  font-size: 1rem !important;
}

.has-medium-font-size {
  font-size: 1.125rem !important;
}

.has-large-font-size {
  font-size: 2.25rem !important;
}

.has-huge-font-size {
  font-size: 2.625rem !important;
}

// .content-main-wrapper {
//   @media only screen and (min-width: 960px) {
//     // min-height: calc(100vh - 535px);
//   }
// }

.expandable-ctas>div {
  padding: 0 !important;
}

.expandable-ctas>div>div {
  padding: .5rem;
}

#podium-website-widget {
  z-index: 3;
  position: relative;
}

/* @file
 * Messages
 *
 */
.messages.messages--warning,
.messages.messages--warning .card,
.toast.messages--warning,
.badge.messages--warning,
span.messages--warning.marker {
  background-color: #f9a825 !important;
  color: #FFFFFF;
}

.messages.messages--error,
.messages.messages--error .card,
.toast.messages--error,
.badge.messages--error,
span.messages--error.marker {
  background: #E53935 !important;
  color: #FFFFFF;
}

.messages.messages--status,
.messages.messages--status .card,
.toast.messages--status,
.badge.messages--status,
span.messages--status.marker {
  background: #4CAF50 !important;
  color: #FFFFFF;
}

.messages {
  padding: 10px;
}

.messages::before,
.messages .message-number {
  padding-right: 10px;
}

.messages.ma-status-messages {
  background: #fafafa !important;
}

.messages-clone {
  background: #fafafa !important;
}

.messages-clone.messages--status .card-content {
  border: 2px solid #4CAF50 !important;
  color: #000000;
}

.messages-clone.messages--status .card-content .message-header .card-title::before {
  color: #4CAF50;
  content: 'check';
}

.messages-clone.messages--warning .card-content {
  border: 2px solid #f9a825 !important;
  color: #000000;
}

.messages-clone.messages--warning .card-content .message-header .card-title::before {
  color: #f9a825;
  content: 'warning';
}

.messages-clone.messages--error .card-content {
  border: 2px solid #E53935 !important;
  color: #000000;
}

.messages-clone.messages--error .card-content .message-header .card-title::before {
  color: #E53935;
  content: 'error';
}

.messages-clone .card-title::before,
.messages-clone .card-title .message-number {
  padding-right: 10px;
}

.messages-clone .card-content .messages__list {
  margin-top: 0;
  padding-left: 30px;
}

.messages-clone .card-content .messages__list li.messages__item {
  margin-bottom: 10px;
  border-bottom: 1px solid #616161;
  padding-bottom: 10px;
}

.messages-clone .card-content .messages__list li.messages__item:last-child {
  border-bottom: 0;
}

.message-trigger {
  color: #000000;
}

.message-trigger .mt-text {
  margin-right: 5px;
  margin-left: 5px;
  color: #000000;
}

.message-trigger i.material-icons,
.message-trigger i.button-action::before,
.message-trigger .collapsible li i.collapsible-header.with-arrows::after,
.collapsible li .message-trigger i.collapsible-header.with-arrows::after,
.message-trigger details.form-wrapper li i.collapsible-header.with-arrows::after,
details.form-wrapper li .message-trigger i.collapsible-header.with-arrows::after,
.message-trigger .collapsible li i.collapsible-header.right-arrows::after,
.collapsible li .message-trigger i.collapsible-header.right-arrows::after,
.message-trigger details.form-wrapper li i.collapsible-header.right-arrows::after,
details.form-wrapper li .message-trigger i.collapsible-header.right-arrows::after,
.message-trigger .region-title__action i.button::after,
.region-title__action .message-trigger i.button::after,
.message-trigger .color-palette i.color-palette__hook.is-up::before,
.color-palette .message-trigger i.color-palette__hook.is-up::before,
.message-trigger .color-palette i.color-palette__lock::before,
.color-palette .message-trigger i.color-palette__lock::before,
.message-trigger i.file::before,
.message-trigger .details-item i.collapsible-header::before,
.details-item .message-trigger i.collapsible-header::before,
.message-trigger .collection .collection-item>.item-list .collection i.collection-item::before,
.collection .collection-item>.item-list .collection .message-trigger i.collection-item::before,
.message-trigger i.dropdown-button::after,
.message-trigger .select-wrapper i.caret::after,
.select-wrapper .message-trigger i.caret::after,
.message-trigger .messages-clone.messages--status .card-content .message-header i.card-title::before,
.messages-clone.messages--status .card-content .message-header .message-trigger i.card-title::before,
.message-trigger .messages-clone.messages--warning .card-content .message-header i.card-title::before,
.messages-clone.messages--warning .card-content .message-header .message-trigger i.card-title::before,
.message-trigger .messages-clone.messages--error .card-content .message-header i.card-title::before,
.messages-clone.messages--error .card-content .message-header .message-trigger i.card-title::before,
.message-trigger i.tablesort.tablesort--asc::after,
.message-trigger i.tablesort.tablesort--desc::after,
.message-trigger .draggable a.tabledrag-handle i.handle::before,
.draggable a.tabledrag-handle .message-trigger i.handle::before,
.message-trigger .views-ui-rearrange-filter-form .filter-group-operator-row i.form-item-filter-groups-operator::before,
.views-ui-rearrange-filter-form .filter-group-operator-row .message-trigger i.form-item-filter-groups-operator::before,
.message-trigger .views-ui-display-tab-bucket i.collection-string-only::before,
.views-ui-display-tab-bucket .message-trigger i.collection-string-only::before,
.message-trigger .acquiadam-browser-breadcrumb-container i::after,
.acquiadam-browser-breadcrumb-container .message-trigger i::after,
.message-trigger .acquiadam-browser-breadcrumb-container li:first-child i::before,
.acquiadam-browser-breadcrumb-container li:first-child .message-trigger i::before,
.message-trigger .entities-list i.item-container.ui-sortable-handle::before,
.entities-list .message-trigger i.item-container.ui-sortable-handle::before,
.message-trigger .system-status-report__status-icon--checked i.collapsible-header::before,
.system-status-report__status-icon--checked .message-trigger i.collapsible-header::before,
.message-trigger .system-status-report__status-icon--warning i.collapsible-header::before,
.system-status-report__status-icon--warning .message-trigger i.collapsible-header::before,
.message-trigger .system-status-report__status-icon--error i.collapsible-header::before,
.system-status-report__status-icon--error .message-trigger i.collapsible-header::before {
  color: #FFFFFF;
  font-size: 24px;
}

.message-trigger i,
.message-trigger span {
  vertical-align: middle;
}

.message-trigger span.badge,
.message-trigger span.marker {
  display: none;
  margin-left: 5px;
  border-radius: 1em;
  min-width: 2em;
  height: 2em;
  vertical-align: middle;
  text-align: center;
  line-height: 2em;
  color: #FFFFFF;
  font-size: .8em;
}

.material_login .message-trigger i.material-icons,
.material_login .message-trigger i.button-action::before,
.material_login .message-trigger .collapsible li i.collapsible-header.with-arrows::after,
.collapsible li .material_login .message-trigger i.collapsible-header.with-arrows::after,
.material_login .message-trigger details.form-wrapper li i.collapsible-header.with-arrows::after,
details.form-wrapper li .material_login .message-trigger i.collapsible-header.with-arrows::after,
.material_login .message-trigger .collapsible li i.collapsible-header.right-arrows::after,
.collapsible li .material_login .message-trigger i.collapsible-header.right-arrows::after,
.material_login .message-trigger details.form-wrapper li i.collapsible-header.right-arrows::after,
details.form-wrapper li .material_login .message-trigger i.collapsible-header.right-arrows::after,
.material_login .message-trigger .region-title__action i.button::after,
.region-title__action .material_login .message-trigger i.button::after,
.material_login .message-trigger .color-palette i.color-palette__hook.is-up::before,
.color-palette .material_login .message-trigger i.color-palette__hook.is-up::before,
.material_login .message-trigger .color-palette i.color-palette__lock::before,
.color-palette .material_login .message-trigger i.color-palette__lock::before,
.material_login .message-trigger i.file::before,
.material_login .message-trigger .details-item i.collapsible-header::before,
.details-item .material_login .message-trigger i.collapsible-header::before,
.material_login .message-trigger .collection .collection-item>.item-list .collection i.collection-item::before,
.collection .collection-item>.item-list .collection .material_login .message-trigger i.collection-item::before,
.material_login .message-trigger i.dropdown-button::after,
.material_login .message-trigger .select-wrapper i.caret::after,
.select-wrapper .material_login .message-trigger i.caret::after,
.material_login .message-trigger .messages-clone.messages--status .card-content .message-header i.card-title::before,
.messages-clone.messages--status .card-content .message-header .material_login .message-trigger i.card-title::before,
.material_login .message-trigger .messages-clone.messages--warning .card-content .message-header i.card-title::before,
.messages-clone.messages--warning .card-content .message-header .material_login .message-trigger i.card-title::before,
.material_login .message-trigger .messages-clone.messages--error .card-content .message-header i.card-title::before,
.messages-clone.messages--error .card-content .message-header .material_login .message-trigger i.card-title::before,
.material_login .message-trigger i.tablesort.tablesort--asc::after,
.material_login .message-trigger i.tablesort.tablesort--desc::after,
.material_login .message-trigger .draggable a.tabledrag-handle i.handle::before,
.draggable a.tabledrag-handle .material_login .message-trigger i.handle::before,
.material_login .message-trigger .views-ui-rearrange-filter-form .filter-group-operator-row i.form-item-filter-groups-operator::before,
.views-ui-rearrange-filter-form .filter-group-operator-row .material_login .message-trigger i.form-item-filter-groups-operator::before,
.material_login .message-trigger .views-ui-display-tab-bucket i.collection-string-only::before,
.views-ui-display-tab-bucket .material_login .message-trigger i.collection-string-only::before,
.material_login .message-trigger .acquiadam-browser-breadcrumb-container i::after,
.acquiadam-browser-breadcrumb-container .material_login .message-trigger i::after,
.material_login .message-trigger .acquiadam-browser-breadcrumb-container li:first-child i::before,
.acquiadam-browser-breadcrumb-container li:first-child .material_login .message-trigger i::before,
.material_login .message-trigger .entities-list i.item-container.ui-sortable-handle::before,
.entities-list .material_login .message-trigger i.item-container.ui-sortable-handle::before,
.material_login .message-trigger .system-status-report__status-icon--checked i.collapsible-header::before,
.system-status-report__status-icon--checked .material_login .message-trigger i.collapsible-header::before,
.material_login .message-trigger .system-status-report__status-icon--warning i.collapsible-header::before,
.system-status-report__status-icon--warning .material_login .message-trigger i.collapsible-header::before,
.material_login .message-trigger .system-status-report__status-icon--error i.collapsible-header::before,
.system-status-report__status-icon--error .material_login .message-trigger i.collapsible-header::before {
  color: #000000;
}

.message-action {
  margin-right: 0;
  margin-left: 3rem;
  font-size: .8rem;
  font-weight: 500;
}

.messages--warning,
.messages--status,
.messages--error {
  box-shadow: none !important;
  background-image: none !important;
}

.messages--warning.toast,
.messages--status.toast,
.messages--error.toast {
  color: #FFFFFF;
}

#notification-wrapper {
  display: none;
  margin-top: 1.5em;
}

a.modal-trigger {
  transition: color .3s ease;
  padding: .5em;
  text-transform: uppercase;
  text-decoration: none;
  color: #000000;
}

a.modal-trigger:hover {
  color: #616161;
}

.description.modal-trigger {
  display: block;
  margin: 10px 5px;
  padding: 0;
  text-transform: none;
  color: #1E88E5;
  font-size: .8em;
}

.modal-content .notification-title {
  border-bottom: 1px solid #616161;
  padding-bottom: 10px;
  font-size: 1.7rem;
}

// Override sponsors carousel
.sponsor-carousel {
  padding: 1rem 2rem !important;
  border: solid thin #efefef;

  .slick-track .smallCarouselImage {
    min-height: 80px !important;
  }

  .slick-slider>div:not(.slick-list) {
    background: transparent !important;
    width: 10px;

    &:first-child {
      left: -1.5rem;
    }

    &:last-child {
      right: -.5rem;
    }

    svg {
      color: #ccc;
    }
  }

  .slick-slide>div>div {

    // height: 110px !important;
    position: relative !important;

    img {

      height: auto !important;
      width: auto !important;
      object-fit: contain !important;
      max-width: 100% !important;
      min-width: 55px !important;
      min-height: 55px !important;
      // min-height: 1px !important;
      position: absolute !important;
      // top: 50% !important;
      // left: 50% !important;
      // transform: translate(-50%, -50%);
    }
  }
}

.placeholder-logo {

  @media only screen and (max-width: 1010px) {
    div {
      max-height: 300px;
    }

    img {
      height: 300px !important;
      object-fit: contain !important;
    }
  }
}

div .single-slide .smallCarouselImage,
div .two-up-slider .smallCarouselImage {
  min-height: 400px !important;

  @media only screen and (min-height: 800px) {
    min-height: 600px !important;
  }

  @media only screen and (max-width: 800px) {
    min-height: 300px !important;
  }

}

.single-slide img {
  object-fit: contain !important;

  @media only screen and (min-height: 800px) {
    object-fit: cover !important;
    min-height: 600px !important;
  }

  width: 100%;
  min-height: 400px !important;

  @media only screen and (max-width: 800px) {
    min-height: 300px !important;
  }
}


// .MuiTypography-body1 {
//   font-size: 1.2rem !important;
//   -webkit-font-smoothing: antialiased !important;
//   -moz-osx-font-smoothing: grayscale !important;
//   text-rendering: optimizeLegibility !important;
// }

.wp-block-embed-vimeo.aligncenter iframe {
  display: block;
  margin: 0 auto;
}


// WYSIWYG Styles 


table.table-bg-blue,
table.table-bg-charcoal {
  background: #b7e4e0;
  padding: 20px;
  display: block;
  padding-left: 145px;
  width: auto !important;
  position: relative;
  padding-bottom: 12px;


  &:before {
    width: 0;
    height: 0;
    border-left: 150px solid #6fc9c2;
    border-bottom: 150px solid transparent;
    content: '';
    z-index: 1;
    position: absolute;
    left: 0;
    top: 0;
  }

  tbody {
    border: 0;
  }

  tr {
    border: 0;
    background: none;
  }

  tr td {
    font-size: 20px;
    font-weight: 400;
    line-height: 29px;
    font-style: italic;
    border: 0;
    background: none;
    padding: 0;
  }

  tr td p {
    margin-bottom: 10px;
  }

  tr td img {
    padding-left: 30px;
  }
}


table.table-bg-charcoal {

  background: #3f4553;
  color: white;

  a {
    color: #FED36B;
  }

  &:before {
    border-left: 150px solid #0d1119;
  }
}

.table-style-triangle,
.table-style-triangle-charcoal {
  padding-left: 63px;
  border: 0;
  display: block;
  position: relative;

  tr {
    margin: 0;
    padding: 0;
    position: relative;
    display: block;
    background: no-repeat;
    border: 0;
  }

  tbody {
    margin: 0;
    padding: 0;
    position: relative;
    display: block;
    background: no-repeat;
    border: 0;
  }

  tr td {
    margin: 0;
    padding: 0;
    border: 0;
    font-size: 18px;
    line-height: 29px;
    color: #3b3b3b;
  }

  tr td a {
    margin: 0 .5rem 0 0;
    padding: 0;
    border: 0;
    font-size: 18px;
    line-height: 29px;
    font-weight: 400;
    color: #3b3b3b;
  }

  tr td p {
    margin: 0;
    padding: 0;
    border: 0;
    font-size: 18px;
    line-height: 29px;
    color: #3b3b3b;
  }

  &:before {
    width: 0;
    height: 0;
    border-right: 50px solid #6fc9c2;
    border-bottom: 50px solid transparent;
    content: '';
    z-index: 2;
    position: absolute;
    left: 0;
    top: 10px;
  }
}

.table-style-triangle-charcoal {

  &:before {
    border-right: 50px solid #3f4553;
  }
}

#matterport {
  max-width: 80vw;
  height: auto;
  min-height: 610px;
}

.pager-seo {
  margin: 15px 0;
  display: flex;
  padding: 0;
  flex-wrap: wrap;
  list-style: none;
  align-items: center;
  justify-content: center;

  >li {
    list-style-type: none !important;

    &.disabled {
      opacity: 0.4;

      a {
        cursor: default;

        &:hover {
          background-color: unset;
        }
      }
    }

    &.active {
      a {
        cursor: default;
        color: #00235D;

        &:hover {
          background-color: unset;
        }
      }
    }

    a {
      padding: 0 8px;
      cursor: pointer;
      font-size: 24px;
      color: #0071B3;
      box-sizing: border-box;
      text-align: center;
      font-family: 'Open Sans', sans-serif;
      font-weight: 400;
      line-height: 1.43;

      .MuiSvgIcon-root {
        font-size: 1.125rem;
        margin: 0 -8px;
        fill: currentColor;
        width: 1em;
        height: .9em;
        display: inline-block;
        transition: fill 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
        flex-shrink: 0;
        user-select: none;
      }

      &:hover {
        background-color: rgba(0, 0, 0, 0.04);
      }
    }
  }
}


// Custom Button CTAs
.phone-cta-button a {
  background: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='16' height='16' fill='%23777' class='bi bi-telephone-fill' viewBox='0 0 16 16'%3E%3Cpath fill-rule='evenodd' d='M1.885.511a1.745 1.745 0 0 1 2.61.163L6.29 2.98c.329.423.445.974.315 1.494l-.547 2.19a.68.68 0 0 0 .178.643l2.457 2.457a.68.68 0 0 0 .644.178l2.189-.547a1.75 1.75 0 0 1 1.494.315l2.306 1.794c.829.645.905 1.87.163 2.611l-1.034 1.034c-.74.74-1.846 1.065-2.877.702a18.6 18.6 0 0 1-7.01-4.42 18.6 18.6 0 0 1-4.42-7.009c-.362-1.03-.037-2.137.703-2.877z'/%3E%3C/svg%3E") 1rem center no-repeat;
  padding-left: 1.6rem;
  padding-right: 2rem;
  border: solid thin #d1d1d1;
  padding: 1rem 2rem 1rem 3.5rem;
  margin: 1rem 0;
  display: inline-block;
  background-color: #fdfdfd;
  font-size: 1.3rem;
  font-weight: bold;
  background-size: 1.5rem;
}

p.phone-cta-button a:hover {
  background-color: #e8e8e8;
  text-decoration: none;
}

.phone-cta-button.primary a {
  background: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='16' height='16' fill='%23777' class='bi bi-telephone-fill' viewBox='0 0 16 16'%3E%3Cpath fill-rule='evenodd' d='M1.885.511a1.745 1.745 0 0 1 2.61.163L6.29 2.98c.329.423.445.974.315 1.494l-.547 2.19a.68.68 0 0 0 .178.643l2.457 2.457a.68.68 0 0 0 .644.178l2.189-.547a1.75 1.75 0 0 1 1.494.315l2.306 1.794c.829.645.905 1.87.163 2.611l-1.034 1.034c-.74.74-1.846 1.065-2.877.702a18.6 18.6 0 0 1-7.01-4.42 18.6 18.6 0 0 1-4.42-7.009c-.362-1.03-.037-2.137.703-2.877z'/%3E%3C/svg%3E") 1rem center no-repeat;
  background-color: #fdd36a;
  background-size: 1.5rem;
  color: #3f4553;
}

p.phone-cta-button.primary a:hover {
  background-color: #f2c85f;
  text-decoration: none;
  color: #3f4553;
}

@media only screen and (max-width: 768px) {
  .phone-cta-button a {
    background-size: 1.8rem;
    padding: 1rem 4rem 1rem 4.5rem;
    line-height: 1.2;
  }


}
